import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/tour/tour.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tour: Tools to Take Action`}</h1>
    <h2>{`Alerts `}{`&`}{` Live Person Takeover`}</h2>
    <p>{`With Dashbot Alerts, you can be notified of key events to take action in real time, including inserting a live agent to help lead users through to conversion.`}</p>
    <p>{`Alerts can be configured based on regular expressions, Intents, button presses, sentiment, and more. Notifications can be sent via email, webhook, or Slack, and will include a link to the live transcript in order to take action.`}</p>
    <p>{`With Dashbot’s Live Person Takeover, you can pause a chatbot to insert a live agent into a session in real time, to help the user through to conversion or increase engagement.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/BV4F1X-WoVI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h2>{`Audience Segmentation`}</h2>
    <p>{`With Dashbot’s `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/features/audience-builder"
      }}>{`Audience Builder`}</a>{`, you can segment the audience to better understand usage behavior, re-engage users through broadcast messaging, or acquire additional users through look-a-like campaigns.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/2M-QgFrYrCc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h2>{`Broadcast Messaging`}</h2>
    <p>{`With Broadcast Messaging, you can send messages to users based on the analytics to bring them back into your chatbot.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/VqCA7IRaQFI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h2>{`Phrase Clustering`}</h2>
    <p>{`Dashbot Phrase Clustering groups similar messages together, whether you are using an NLP engine or not.`}</p>
    <p>{`Identify mishandled and unhandled Intents to improve NLP response effectiveness and increase user satisfaction.`}</p>
    <p>{`Download the phrases to retrain your NLP engine.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/Gn86aDSWD9k" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      